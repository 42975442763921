<template>
  <div class="item1Reports container">

	<p class="h6 text-center text-primary"><b>{{ item1 }} Inventory Report</b></p>    
	<br>
	<p class="text-danger" align="left">{{alert}}</p>

	<CCard :color="'secondary'">
		<CCardBody>
			<form class="form-inline justify-content-center">
				<div class="form-group">
					<label class="font-weight-bold mr-1" for="fromdate">From:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-2" v-model="fromdate" name="fromdate"></datepicker>

					<label class="font-weight-bold mr-1" for="todate">To:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-4" v-model="todate" name="todate"></datepicker>

					<CButton color="dark" v-on:click.prevent="serverCall('getItem1ReportData')">Fetch Data</CButton>

				</div>

				<div>
					<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
					<scale-loader :loading="loading"></scale-loader>
				</div>

			</form>	
		</CCardBody>
	</CCard>
	
	<br>
	<div>
		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:sort-options="{
				enabled: false,
			}"
			:group-options="{
				enabled: true,
   				collapsable: false
			}"
			styleClass="vgt-table condensed"
		>

		<div slot="table-actions">
			<CButton color="dark" v-on:click.prevent="downloadReport()">Download Report</CButton>
		</div>

		<template slot="table-column" slot-scope="props">
        	<span v-if="props.column.in_out === 'In'">
          		{{props.column.label}}<br>In
        	</span>
        	<span v-else-if="props.column.in_out === 'Out'">
          		{{props.column.label}}<br>Out
        	</span>
        	<span v-else>{{props.column.label}}</span>
      	</template>
	  </vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'item1Reports',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			alert: '',
			columns: [],
			rows: [],

			item1: this.$route.params.item1,
			fromdate: null,
			todate: null,

			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(), // Disable all dates after specific date
			},

			loading: false,

			reportName: this.$route.params.item1,
			reportLongName: "",
			reportData: "",
			//excelFields 
		
		};
	},

	computed: {
	},

	mounted(){
		// todate = current date
		this.orgId = this.$route.params['orgId']
		this.fromdate = new Date()
		this.todate = new Date()
		this.item1 = this.$route.params['item1']
		//this.serverCall('getLocationReportData')
	},

	methods: {

		//thClassFunc() {
		//	return 'custom-th-class'
		//},

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getItem1ReportData":
								console.log("version 1.04")
								self.getItem1ReportData(idToken)
								break

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Report Data for the given dates.
        getItem1ReportData(authToken) {
            var self = this

            if (!this.fromdate || !this.todate || !this.item1){
                self.alert = "Either fromdate or todate or item1 not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Inventory..."

			var postJson = {
				"item1": this.item1,
				"fromDate": this.getMidDay(this.fromdate),
				"toDate": this.getMidDay(this.todate),
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetItem1ReportData/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getItem1ReportData - error:" + response.data.err)
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						self.reportLongName = self.reportName + " - " + fdStr + " To " + tdStr
						self.reportData = response.data.reportData
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

		downloadReport() {
			if (this.reportData==[]){
				this.alert = "No report data."
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},

		base64toBlob(base64Data, contentType) {
			contentType = contentType || '';
			let sliceSize = 1024;
			let byteCharacters = atob(base64Data);
			let bytesLength = byteCharacters.length;
			let slicesCount = Math.ceil(bytesLength / sliceSize);
			let byteArrays = new Array(slicesCount);
			for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
				let begin = sliceIndex * sliceSize;
				let end = Math.min(begin + sliceSize, bytesLength);

				let bytes = new Array(end - begin);
				for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters[offset].charCodeAt(0);
				}
				byteArrays[sliceIndex] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		},


	},

}
</script>

<style>
.active {
  color : green;
}
.custom-th-class {
  text-align: center;	
}
</style>

