<template>

</template>

<script>
//import moment from 'moment'
//const moment = require('moment-timezone')

export default {
  name: "Helper",

  components: {

  },

  data() {
    return {

    }
  },

  methods: {
  	removeArrayElement(arr, value) {
			var index = arr.indexOf(value);
			if (index > -1) {
				arr.splice(index, 1);
			}
			return arr;
		},

    getMidDay(dt){
      if (typeof(dt) == 'string')
        dt = new Date(dt)
      dt.setHours(12)
      dt.setMinutes(0)
      dt.setSeconds(0)
      dt.setMilliseconds(0)
      return dt
    },

    gotoLogin(){
      this.$router.push({ name: "login"});
    },

    // Get Document extension
    getExtension(filename) {
        var parts = filename.split('.');
        var ext = parts[parts.length - 1];
        return ext.toLowerCase();
    },

		base64toBlob(base64Data, contentType) {
			contentType = contentType || '';
			let sliceSize = 1024;
			let byteCharacters = atob(base64Data);
			let bytesLength = byteCharacters.length;
			let slicesCount = Math.ceil(bytesLength / sliceSize);
			let byteArrays = new Array(slicesCount);
			for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
				let begin = sliceIndex * sliceSize;
				let end = Math.min(begin + sliceSize, bytesLength);

				let bytes = new Array(end - begin);
				for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters[offset].charCodeAt(0);
				}
				byteArrays[sliceIndex] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		},

    // Download signed file
    downloadData(data, filename, type) {
      var file = new Blob([data], { type: type });

      if (window.navigator.msSaveOrOpenBlob)
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      else {
        // Others
        var a = document.createElement("a"),
          url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    },

    // Helper fun to convert dataURL to blob with correct mime.
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    // [PN] downloadData wont take data.content. so added a new method downloadDataContent
    downloadDataContent(dataContent, filename, type) {
      var file = this.dataURLtoBlob(dataContent);

      if (window.navigator.msSaveOrOpenBlob)
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      else {
        // Others
        var a = document.createElement("a"),
          url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    },

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
